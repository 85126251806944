
import Dotace from '../../assets/dotace.png'
import Dotacni from '../../assets/dotacni.png'

export const ImagesHome = () => (
    <>
        <img src={Dotacni} alt="dotacni" style={{
            maxWidth: '100%',
            height: 'auto',
            position: 'absolute',
            top: '45%',
            right: '-25%',
            zIndex: '-1'
        }} />
        <img src={Dotace} alt="dotace" style={{
            maxWidth: '100%',
            height: 'auto',
            position: 'absolute',
            top: '-25%',
            zIndex: '-1'

        }} />
    </>
)