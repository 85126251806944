import React from "react";
import { format } from "date-fns";
import { handleSimpleChanges } from "../../../common";
import { COLORS } from "../../../common/colors";
import { Grid } from "semantic-ui-react";

const singleDetail = (headline, data, change) => {
  return (
    <div style={{ marginBottom: "2em" }}>
      <h2 style={{ color: COLORS.DEFAULT_BLUE }}>{headline}</h2>
      {handleSimpleChanges(change, data)}
    </div>
  );
};

const linkDetail = (headline, link, url) => {
  return (
    <div style={{ marginBottom: "2em" }}>
      <h2 style={{ color: COLORS.DEFAULT_BLUE }}>{headline}</h2>
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <div>{link}</div>
      </a>
    </div>
  );
};

const GrantBasicInfo = ({ grant, changes }) => {
  return (
    <Grid flexDirection="row" gap="50px" stackable>
      {singleDetail(
        "Začátek",
        format(new Date(grant.start_date), "dd.MM.yyyy"),
        changes.start_date
      )}
      {singleDetail(
        "Konec",
        format(new Date(grant.deadline_date), "dd.MM.yyyy"),
        changes.deadline_date
      )}
      {singleDetail("Doména", grant.domain.domain)}
      {linkDetail("Zdroj", "Originální zdroj", grant.url)}
    </Grid>
  );
};

export default GrantBasicInfo;
