import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import Menu from "./Header";
const Loading = () => {
  return (
    <div style={{ height: "100%" }}>
      <Menu />
      <Dimmer active inverted>
        <Loader active size="large">
          Připravuji data
        </Loader>
      </Dimmer>
    </div>
  );
};

export default Loading;
