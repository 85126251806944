import { useSelector } from "react-redux";

export const useFilter = () => {
  const currentPage = useSelector((state) => state.common.currentPage);
  const search = useSelector((state) => state.common.search);
  const domain = useSelector((state) => state.common.domain);
  const program = useSelector((state) => state.common.program);
  const category = useSelector((state) => state.common.name);
  const how = useSelector((state) => state.common.how);
  const by = useSelector((state) => state.common.by);
  const status = useSelector((state) => state.common.status);
  const wasSeen = useSelector((state) => state.common.wasSeen);
  const hasNews = useSelector((state) => state.common.hasNews);

  return {
    currentPage,
    search,
    domain,
    program,
    category,
    how,
    by,
    status,
    wasSeen,
    hasNews,
  };
};

const OPERATIONS = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
};
export const handleSimpleChanges = (modification, text) => {
  if (modification === OPERATIONS.CREATE) {
    return <span style={{ background: "rgba(0, 151, 19, 0.3)" }}>{text}</span>;
  }

  if (modification === OPERATIONS.UPDATE) {
    return (
      <span style={{ background: "rgba(255, 147, 35, 0.3)" }}>{text}</span>
    );
  }

  if (modification === OPERATIONS.DELETE) {
    return (
      <span
        style={{
          background: "rgba(189, 42, 0, 0.3)",
          textDecoration: "line-through",
        }}
      >
        {text}
      </span>
    );
  }

  return <span>{text}</span>;
};

export const handleArrayOperationsLinks = (
  row,
  modifiedItems,
  deletedItems,
  key = undefined
) => {
  const typeOfOperation = modifiedItems.includes(row.name)
    ? OPERATIONS.UPDATE
    : deletedItems.includes(row.name)
    ? OPERATIONS.DELETE
    : undefined;

  return (
    <a href={row.url} target="_blank" rel="noreferrer">
      {handleSimpleChanges(typeOfOperation, row.name)}
    </a>
  );
};

export const handleArrayOperationsNormal = (
  row,
  modifiedItems,
  deletedItems,
  key = undefined
) => {
  const typeOfOperation = modifiedItems.includes(row[key] ? row[key] : row)
    ? OPERATIONS.UPDATE
    : deletedItems.includes(row[key] ? row[key] : row)
    ? OPERATIONS.DELETE
    : undefined;

  return (
    <div className="item" key={key ? row[key] : row}>
      {handleSimpleChanges(typeOfOperation, key ? row[key] : row)}
    </div>
  );
};
