import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Popup, Radio } from "semantic-ui-react";
import { editPage, fetchGrants } from "../../../actions";
import { useFilter } from "../../../common";
import { COLORS } from "../../../common/colors";
import { useLogout } from "../../../helper/hooks/useLogout";
import { useUser } from "../../../helper/hooks/useUser";

import "./filter/filter.css";

const CheckboxFilter = ({ val, label, other, popupContent }) => {
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const selectedOption = useSelector((state) => state.common[val]);
  const otherOption = useSelector((state) => state.common[other]);
  const filter = useFilter();
  const { userToken } = useUser();

  if (selectedOption !== undefined) {
    return (
      <div
        onClick={() => {
          dispatch(editPage({ [val]: !selectedOption }));
          const wasSeen = val === "wasSeen" ? !selectedOption : otherOption;
          const hasNews = val === "hasNews" ? !selectedOption : otherOption;
          dispatch(editPage({ currentPage: 1 }));
          dispatch(
            fetchGrants({ ...filter, wasSeen, hasNews, userToken, logout })
          );
        }}
        style={{ cursor: "pointer" }}
      >
        <div>
          <Radio
            slider
            checked={selectedOption}
            style={{ marginRight: "10px" }}
          />
          <span>
            <span style={{ marginRight: "10px" }}>{label(selectedOption)}</span>
            {popupContent && (
              <Popup
                trigger={
                  <Icon
                    name="info"
                    size="small"
                    circular
                    style={{ color: COLORS.CONTRAST_COLOR }}
                  />
                }
                content={popupContent}
                position="top left"
              />
            )}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export default CheckboxFilter;
