import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { editPage } from "../../../../actions";
import { COLORS } from "../../../../common/colors";

import "./filter.css";

const getText = (selected, options, red) => {
  const textToDisplay = Object.values(options).filter(
    (option) => option[red] === selected
  );

  return textToDisplay[0];
};

const compareObjects = (object1, object2, key = "text") => {
  const obj1 = object1[key].toUpperCase();
  const obj2 = object2[key].toUpperCase();

  if (obj1 < obj2) {
    return -1;
  }
  if (obj1 > obj2) {
    return 1;
  }
  return 0;
};

const Filter = ({ text, red, val, search, sort }) => {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.common[red]);
  const selectedOption = useSelector((state) => state.common[val]);

  const c = [];
  if (options) {
    Object.values(options).forEach((option) => {
      c.push({ key: option[red], value: option[red], text: option[val] });
    });

    if (sort) {
      c.sort((a, b) => {
        return compareObjects(a, b);
      });
    }

    return (
      <Dropdown
        clearable
        selection
        search={search}
        value={
          selectedOption !== 0
            ? getText(selectedOption, options, red)[red]
            : undefined
        }
        options={c}
        text={
          selectedOption !== 0
            ? getText(selectedOption, options, red)[val]
            : text
        }
        style={{
          borderColor: `${
            selectedOption !== 0
              ? `${COLORS.CONTRAST_COLOR}`
              : "rgba(34,36,38,.15)"
          }`,
          color: `${selectedOption !== 0 ? "#28282B" : "grey"}`,
        }}
        onChange={(_, data) => {
          data.value === ""
            ? dispatch(editPage({ [val]: 0 }))
            : dispatch(editPage({ [val]: data.value }));

          dispatch(editPage({ currentPage: 1 }));
        }}
      />
    );
  }

  return null;
};

export default Filter;
