import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Icon } from "semantic-ui-react";
import { editPage } from "../../../../../actions";
import { COLORS } from "../../../../../common/colors";

const getText = (selected, options, red) => {
  const textToDisplay = Object.values(options).filter(
    (option) => option[red] === selected
  );

  return textToDisplay[0];
};

const OrderFilter = ({ text, red, val, search }) => {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.common[red]);
  const filterBy = useSelector((state) => state.common.by);
  const filterHow = useSelector((state) => state.common.how);
  const selectedOption = useSelector((state) => state.common[val]);

  const c = [];
  if (options) {
    Object.values(options).forEach((option) => {
      c.push({ key: option[red], value: option[red], text: option[val] });
    });

    return (
      <React.Fragment>
        <Dropdown
          clearable
          selection
          search={search}
          style={{
            border: `1px solid ${selectedOption ? `${COLORS.CONTRAST_COLOR}` : "rgba(34,36,38,.15)"
              }`,
            color: selectedOption ? "black" : "grey",
          }}
          value={
            selectedOption !== 0
              ? getText(selectedOption, options, red)[red]
              : undefined
          }
          options={c}
          text={
            selectedOption !== 0
              ? getText(selectedOption, options, red)[val]
              : text
          }
          onChange={(_, data) => {
            data.value === ""
              ? dispatch(editPage({ [val]: 0 }))
              : dispatch(editPage({ [val]: data.value }));
            dispatch(editPage({ currentPage: 1 }));
          }}
        />
        <div style={{ display: "inline", marginLeft: "1em" }}>
          <Icon
            link
            size="large"
            style={{color: `${filterBy === 0 ? "grey" : `${COLORS.DEFAULT_BLUE}`}`}}
            name={`arrow ${filterHow === 1 ? "up" : "down"}`}
            onClick={() => {
              dispatch(editPage({ how: filterHow === 1 ? 2 : 1 }));
              dispatch(editPage({ currentPage: 1 }));
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  return null;
};

export default OrderFilter;
