import React, { useState } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import AppPage from "./homePage/Home";
import GrantPage from "./grantPage/Grant";
import LoginPage from "./loginPage/Login";
import GrantModal from "./modal/Modal";

const App = () => {
  const [isModalOpen, setModelOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const initModal = () =>
    setTimeout(() => {
      const loc = window.location.href;

      if (
        !localStorage.getItem("token") &&
        !loc.includes("login") &&
        !isDirty
      ) {
        setIsDirty(true);
        setModelOpen(true);
      }
    }, 5000);

  return (
    <div onClick={() => initModal()} style={{ height: "100%" }}>
      <GrantModal isOpen={isModalOpen} setModelOpen={setModelOpen} />
      <Router>
        <Switch>
          <Route exact path="/" component={AppPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/grant/:id" component={GrantPage} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
