import React from "react";
import { useSelector } from "react-redux";
import Search from "../../Search";
import OrderFilter from "./OrderFilter";

import "../../filter/filter.css";
import { TEXTS } from "../../../../../common/texts";
import { Grid } from "semantic-ui-react";

const TopFilter = ({ text, red, val, search, disabled }) => {
  const options = useSelector((state) => state.common[red]);

  const c = [];
  if (options) {
    Object.values(options).forEach((option) => {
      c.push({ key: option[red], value: option[red], text: option[val] });
    });

    return (
      <Grid>
        <Grid.Row style={{ display: "inline", textAlign: "justify" }}>
          {TEXTS.HEADER}
        </Grid.Row>

        <Grid.Row stretched>
          <div style={{ width: "calc(66.6666666666666% - 2.8em)" }}>
            <Search />
          </div>
          {!disabled && (
            <div style={{ textAlign: "right", width: "33.333333%" }}>
              <span style={{ marginRight: "1em" }}>Řadit dle:</span>
              <OrderFilter text={text} red={red} val={val} search={search} />
            </div>
          )}
        </Grid.Row>
      </Grid>
    );
  }

  return null;
};

export default TopFilter;
