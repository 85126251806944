import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Header, Button, Grid, Container, Form } from "semantic-ui-react";
import { login } from "../../actions";
import { COLORS } from "../../common/colors";
import { LoginControllerInput } from "./components/Controller";
import { LoginErrors } from "./components/LoginErrors";
import { Layout } from "../Layout";
import { isMobile } from "react-device-detect";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.common.isLoading);
  const [error, setError] = useState();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: "",
      username: "",
    },
  });

  const successfullyLogged = () => {
    history.push("/");
  };

  const clearErrors = () => {
    setError();
  };
  const onSubmit = (data) => {
    dispatch(login(data, setError, successfullyLogged));
  };
  return (
    <Layout>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ textAlign: "center" }}>
        <Header id="fonts" style={isMobile ? {} : { marginBottom: "4vh" }}>
          <div className="content" style={{ color: `${COLORS.DEFAULT_BLUE}` }}>
            Přihlášení
          </div>
        </Header>
        <Container>
          <Grid
            centered
            style={{
              flexDirection: "column",
            }}
          >
            <Grid.Row>
              <LoginControllerInput
                control={control}
                name={"username"}
                label={"Přihlašovací jméno"}
                type={"text"}
                required
              />
            </Grid.Row>
            <Grid.Row>
              <LoginControllerInput
                control={control}
                name="password"
                label={"Heslo"}
                type={"password"}
                required
              />
            </Grid.Row>
            <LoginErrors errors={error} clearErrors={clearErrors} />
          </Grid>
        </Container>
        <Button
          style={{
            marginTop: "5vh",
            width: `${isMobile ? "13em" : "25em"}`,
            color: "white",
            backgroundColor: COLORS.CONTRAST_COLOR,
          }}
          loading={isLoading}
        >
          Přihlásit se
        </Button>
      </Form>
    </Layout>
  );
};

export default LoginPage;
