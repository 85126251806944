import Country from "../../../assets/country.png";

const zasady = (
  <a
    href="https://www.dotacni.info/zasady-ochrany-osobnich-udaju/"
    target="_blank"
    rel="noreferrer"
    style={{ color: "white" }}
  >
    <strong> Zásady ochrany osobních údajů </strong>
  </a>
);

const smluvni = (
  <a
    href="https://www.dotacni.info/smluvni-podminky/"
    target="_blank"
    rel="noreferrer"
    style={{ color: "white" }}
  >
    <strong> Smluvní podmínky </strong>
  </a>
);

export const Footer = () => (
  <div style={{ width: "auto", backgroundColor: "#003369", color: "white" }}>
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
          padding: "10px",
        }}
      >
        <div>
          <strong>Adresa</strong>
          <div>EU LEGAL ADVISORY, s.r.o.</div>
          <div>Jana Babáka 2733/11, 612 00 Brno</div>
          <div>IČO: 293 77 099</div>
        </div>
        <div>
          <strong>Kontaktní informace</strong>
          <div>Telefon: +420 530 338 800</div>
          <div>E-mail: info@dotacni.info</div>
        </div>
      </div>
      <strong>© {new Date().getFullYear()} Dotace EU</strong>
      <div style={{ fontSize: "12px", padding: "10px" }}>
        Veškeré materiály na těchto stránkách nesmí být zveřejněny nebo
        redistribuovány bez povolení. Přečtěte si prosím naše {smluvni} a
        {zasady} před použitím tohoto webu.
      </div>
    </div>
  </div>
);

export const FooterWithImage = () => (
  <div>
    <div>
      <img
        style={{
          width: "100%",
          height: "auto",
          display: "block",
        }}
        alt="footer"
        src={Country}
      />
    </div>
    <Footer />
  </div>
);
