import { Controller } from "react-hook-form";
import { Grid, Input } from "semantic-ui-react";
import { isMobile } from "react-device-detect";

const CustomizedInput = ({ field, label, type }) => {
  if (isMobile) {
    return <Input {...field} placeholder={label} type={type} />;
  }

  return (
    <Grid style={{ display: "inline-block", textAlign: "left" }}>
      <Grid.Row>
        <strong
          style={{
            marginBottom: "-0.5em",
          }}
        >
          {label}
        </strong>
      </Grid.Row>
      <Grid.Row>
        <Input {...field} type={type} style={{ width: "25em" }} />
      </Grid.Row>
    </Grid>
  );
};

export const LoginControllerInput = ({
  control,
  name,
  required,
  label,
  type,
}) => (
  <Controller
    name={name}
    control={control}
    rules={{ required: !!required }}
    render={({ field }) => {
      return <CustomizedInput field={field} label={label} type={type} />;
    }}
  />
);
