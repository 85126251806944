import React from "react";
import { Icon, Message } from "semantic-ui-react";

const ErrorMessage = ({ error }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Message error icon>
        <Icon name="wrench" />
        <Message.Content>
          <Message.Header>Ojoj, neco se pokazilo</Message.Header>
          <p>{error}</p>
        </Message.Content>
      </Message>
    </div>
  );
};

export default ErrorMessage;
