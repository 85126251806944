import React from "react";
import { Header, Icon } from "semantic-ui-react";

const NoGrants = () => (
  <div style={{ textAlign: "center", height: "100%", lineHeight: "50" }}>
    <Header as="h2" icon>
      <Icon name="frown outline" />
      Žádné dotace
      <Header.Subheader>
        Nepodařilo se nám pro Vás najít žádné dotace
      </Header.Subheader>
    </Header>
  </div>
);

export default NoGrants;
