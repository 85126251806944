import { EDIT_PAGE, FETCH_FILTERS, ERROR_MSG } from "../actions/types";

const initialState = {
  currentPage: 1,
  countVisibleGrants: 0,
  domain: 0,
  program: 0,
  name: 0,
  by: 3,
  how: 2,
  status: 1,
  sortHow: {
    1: { how: "Vzestupně", sortHow: 1 },
    2: { how: "Sestupně", sortHow: 2 },
  },
  sortBy: {
    1: { by: "Konec výzvy", sortBy: 1 },
    2: { by: "Začátek výzvy", sortBy: 2 },
    3: { by: "Vytvoření výzvy", sortBy: 3 },
  },
  status_id: {
    1: { status: "Otevřené výzvy", status_id: 1 },
    2: { status: "Uzavřené výzvy", status_id: 2 },
    3: { status: "Připravované výzvy", status_id: 3 },
  },
  search: "",
  wasSeen: false,
  hasNews: false,
  errorMessage: undefined,
  totalCount: 0,
  isLoading: false,
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PAGE:
      return Object.keys(action.payload)[0]
        ? {
            ...state,
            [Object.keys(action.payload)[0]]:
              action.payload[Object.keys(action.payload)[0]],
          }
        : state;
    case FETCH_FILTERS:
      const key = Object.keys(action.payload)[0];
      let values = Object.values(action.payload)[0];
      return { ...state, [key]: values };
    case ERROR_MSG:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};
