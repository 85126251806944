import { FooterWithImage } from "./homePage/components/Footer";
import Menu from "./homePage/components/Header";
import { isMobile } from "react-device-detect";

export const Layout = ({ children }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Menu />
        <div style={isMobile ? { marginTop: "10px" } : { marginTop: "115px" }}>
          {children}
        </div>
        <FooterWithImage />
      </div>
    </>
  );
};
