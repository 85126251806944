import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Divider, Grid, Menu as UIMenu } from "semantic-ui-react";
import { COLORS } from "../../../common/colors";
import DotaceImg from "../../../assets/logo.png";
import MobileIcon from "../../../assets/favicon.png";
import { isBrowser } from "react-device-detect";

const Menu = ({ isDetail }) => {
  const history = useHistory();

  const login = (
    <Button
      as="a"
      onClick={() => history.push(`/login`)}
      inverted
      style={{
        boxShadow: `0 0 0 2px ${COLORS.DEFAULT_BLUE} inset`,
        color: `${COLORS.DEFAULT_BLUE}`,
      }}
    >
      {isBrowser && <i aria-hidden="true" className="user outline icon"></i>}
      Přihlášení
    </Button>
  );

  const handleLogout = () => {
    localStorage.clear();
    history.push(`/`);
  };
  const logout = (
    <Button
      as="a"
      inverted
      style={{
        boxShadow: `0 0 0 2px ${COLORS.DEFAULT_BLUE} inset`,
        color: `${COLORS.DEFAULT_BLUE}`,
      }}
      onClick={handleLogout}
    >
      {isBrowser && <i aria-hidden="true" className="user icon"></i>}
      Odhlášení
    </Button>
  );

  const getMenu = () => {
    if (isBrowser) {
      return (
        <UIMenu
          fixed="top"
          size="large"
          borderless
          style={{ textAlign: "center", padding: 10 }}
        >
          <UIMenu.Item onClick={() => history.push(`/`)} className="menu-logo" position="left">
              <img
                src={DotaceImg}
                style={{
                  minWidth: "214px",
                  display: "block",
                }}
                alt="logo"
              />
          </UIMenu.Item>
          <UIMenu.Item position="right">
              {localStorage.getItem("token") ? logout : login}
          </UIMenu.Item>
        </UIMenu>
      );
    }

    return (
      <>
        <Grid
          style={{
            width: "100%",
            paddingLeft: "2em",
            paddingTop: "2em",
            paddingRight: "2em",
          }}
        >
          <Grid.Column floated="left" style={{ maxHeight: "100px" }}>
            <img
              onClick={() => history.push(`/`)}
              src={MobileIcon}
              style={{ height: "50%", width: "auto" }}
              alt="logo"
            />
          </Grid.Column>
          <Grid.Column floated="right" style={{ left: "-20%" }}>
            {localStorage.getItem("token") ? logout : login}
          </Grid.Column>
          <Grid.Row>
            <Divider style={{ marginTop: "-1em", width: "100%" }} />
          </Grid.Row>
        </Grid>
      </>
    );
  };

  return getMenu();
};

export default Menu;
