import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckboxFilter from "../CheckboxFilter";
import Filter from "../filter/Filter";
import Search from "../Search";
import {
  wasSeenText,
  wasSeenIcon,
  colorWasSeen,
  hasNewsText,
  hasNews,
  colorHasNews,
} from "./leftSide.functions";

import "../filter/filter.css";
import { fetchFilters } from "../../../../actions";
import { TEXTS } from "../../../../common/texts";
import { Grid, Header, Statistic } from "semantic-ui-react";
import { LoggedComponent } from "../LoggedComponent";
import { COLORS } from "../../../../common/colors";
import CircleButton from "../../../../assets/circleButton.png";
import { isMobile } from "react-device-detect";

const LeftSide = () => {
  const dispatch = useDispatch();
  const filterProgram = useSelector((state) => state.common.programs_id);
  const totalNumberOfGrants = useSelector((state) => state.common.totalCount);

  useEffect(() => {
    if (!filterProgram) {
      dispatch(fetchFilters());
    }
  });

  if (!filterProgram) {
    return null;
  }

  return (
    <Grid textAlign="center" style={{ width: "100%", margin: "auto" }}>
      <Statistic style={{ width: "100%", margin: "auto" }}>
        <Statistic.Value style={{ color: `${COLORS.CONTRAST_COLOR}` }}>
          {totalNumberOfGrants}
        </Statistic.Value>
        <Statistic.Label>Dotačních výzev</Statistic.Label>
      </Statistic>
      <Grid.Row style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Grid style={{ display: "inline-block", textAlign: "left" }}>
          <Grid.Row>
            <CheckboxFilter
              label={hasNewsText}
              val="hasNews"
              other="wasSeen"
              renderIcon={hasNews}
              renderColor={colorHasNews}
              popupContent={TEXTS.NEWS}
            />
          </Grid.Row>
          <LoggedComponent>
            <Grid.Row>
              <CheckboxFilter
                label={wasSeenText}
                val="wasSeen"
                other="hasNews"
                renderIcon={wasSeenIcon}
                renderColor={colorWasSeen}
                popupContent={TEXTS.SEEN}
              />
            </Grid.Row>
          </LoggedComponent>
        </Grid>
      </Grid.Row>
      <div className="ui mobile only row">
        <Grid.Row>
          <Search isMobile />
        </Grid.Row>
      </div>
      <Grid.Row>
        <Filter text="Stav výzvy" red="status_id" val="status" />
      </Grid.Row>
      <Grid.Row>
        <Filter text="Doména" red="domains_id" val="domain" sort />
      </Grid.Row>
      {/* Momentálne podľa programu nebudeme filtrovať. Je ale veľká tendencia, že ho budeme v budúcnosti využívať */}
      {/* <Filter text="Program" red="programs_id" val="program" /> */}
      <Grid.Row>
        <Filter text="Příjemce" red="categories_id" val="name" search />
      </Grid.Row>
      <Grid.Row className="ui mobile only row">
        <Filter text="Směr řazení" red="sortHow" val="how" />
      </Grid.Row>
      <Grid.Row className="ui mobile only">
        <Filter text="Řadit podle" red="sortBy" val="by" />
      </Grid.Row>
      <Grid.Row textAlign="center">
        <Header as="h2" id="fonts">
          Mám šanci získat dotaci?
        </Header>
      </Grid.Row>
      <Grid.Row>
        <a
          href="https://www.dotacni.info/kontakty/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{
              position: "relative",
              textAlign: "center",
              display: "block",
              height: "100px",
              width: "auto",
            }}
            alt="footer"
            src={CircleButton}
          />
        </a>
      </Grid.Row>
      <Grid.Row
        style={{
          paddingLeft: `${isMobile ? 1 : 5}em`,
          paddingRight: `${isMobile ? 1 : 5}em`,
          textAlign: `left`,
        }}
      >
        {TEXTS.FOOTER}
      </Grid.Row>
    </Grid>
  );
};

export default LeftSide;
