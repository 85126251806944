import { COLORS } from "../../../../common/colors";

export const wasSeenIcon = (wasSeen) => {
  return wasSeen ? "check" : "check";
};

export const wasSeenText = (wasSeen) => {
  return wasSeen ? "Zpracováno" : "Nezpracováno";
};

export const colorWasSeen = (wasSeen) => {
  return wasSeen ? `${COLORS.CONTRAST_COLOR}` : "grey";
};

export const hasNews = (hasNews) => {
  return hasNews ? "bell" : "star";
};

export const hasNewsText = (hasNews) => {
  return hasNews ? "Novinky" : "Všechny";
};

export const colorHasNews = (hasNews) => {
  return `${COLORS.CONTRAST_COLOR}`;
  // return hasNews ? `${COLORS.CONTRAST_COLOR}` : "grey";
};
