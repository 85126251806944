import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGrants } from "../../actions";
import CardsGrants from "./components/cardsGrants/CardsGrants";
import Paggination from "./components/Paggination";
import LeftSide from "./components/leftSide/LeftSide";
import ErrorMessage from "./components/ErrorMessage";
import Loading from "./components/Loading";

import "./home.css";
import { useFilter } from "../../common";
import { useUser } from "../../helper/hooks/useUser";
import { useLogout } from "../../helper/hooks/useLogout";
import { ImagesHome } from "./ImagesHome";
import { Layout } from "../Layout";
import { Grid } from "semantic-ui-react";
import { isBrowser } from "react-device-detect";

const HomePage = () => {
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const { userToken } = useUser();
  const filters = useFilter();

  const error = useSelector((state) => state.common.errorMessage);
  const isLoading = useSelector((state) => state.common.isLoading);

  useEffect(() => {
    dispatch(fetchGrants({ ...filters, userToken, logout }));
    // Do deps nejde celý objekt filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.currentPage,
    filters.search,
    filters.domain,
    filters.program,
    filters.category,
    filters.how,
    filters.by,
    filters.status,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Layout>
      {error ? (
        <ErrorMessage error={error} />
      ) : (
        <Grid columns={2} stackable>
          <Grid.Column style={{ width: "14em", minWidth: "23%" }}>
            <LeftSide />
          </Grid.Column>
          <Grid.Column style={{ marginRight: "2vw", width: "75%" }}>
            <div>
              <CardsGrants />
            </div>
            <div style={{ marginTop: "10px" }}>
              <Paggination />
            </div>
          </Grid.Column>
          {isBrowser && <ImagesHome />}
        </Grid>
      )}
    </Layout>
  );
};

export default HomePage;
