import { combineReducers } from 'redux';
import grantsReducer from './grantReducer';
import commonReducer from './commonReducer';

const appReducer = combineReducers({
  grants: grantsReducer,
  common: commonReducer,
});

export default appReducer;
