import { FETCH_GRANT, FETCH_GRANTS } from "../actions/types";

// eslint-disable-next-line
export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_GRANT:
      return {
        ...state,
        [action.payload.data.data.grants_id]: action.payload.data,
      };
    case FETCH_GRANTS:
      state = {};
      const map = [action.payload.data.base_grants].reduce(
        (map, item) => map.set(item.id, item),
        new Map()
      );
      return { ...state, ...map.entries().next().value[1] };
    default:
      return state;
  }
};
