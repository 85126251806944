import { useUser } from "../../../helper/hooks/useUser";

export const LoggedComponent = ({ children }) => {
  const { userToken } = useUser();

  if (!userToken) {
    return null;
  }

  return children;
};
