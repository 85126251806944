import { useWindowSize } from "../../../helper/hooks/useWindowSize";
import { editPage } from "../../../actions";
import { COLORS } from "../../../common/colors";
import { Grid, GridColumn, Label } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import React from "react";

const NUM_NUMBERS = 3;

const Pag = () => {
  const { width } = useWindowSize();
  const currentPage = useSelector((state) => state.common.currentPage);
  const totalNumberOfGrants = useSelector((state) => state.common.totalCount);
  const extraPage = totalNumberOfGrants % 9;
  let totalNumberOfPages = Math.floor(totalNumberOfGrants / 9);
  totalNumberOfPages = Boolean(extraPage)
    ? (totalNumberOfPages += 1)
    : totalNumberOfPages;
  const isNextPageAvailable = currentPage * 9 < totalNumberOfGrants;
  const isPreviousPageAvailable = currentPage > 1;

  const dispatch = useDispatch();
  const handleOnPageChange = (newPage) => {
    if (newPage < 1) {
      return 1;
    }
    dispatch(editPage({ currentPage: newPage }));
  };

  if (currentPage === undefined) {
    return null;
  }

  const generatePre = () => {
    let bottomBoundary = currentPage - NUM_NUMBERS;

    if (bottomBoundary < 1) {
      bottomBoundary = 1;
    }

    const labels = [];

    for (let i = bottomBoundary; i < currentPage; i++) {
      const label = (
        <GridColumn verticalAlign="top" textAlign="right" key={i}>
          <Label
            size="large"
            circular
            key={i}
            style={{
              cursor: "pointer",
              backgroundColor: `${COLORS.CONTRAST_COLOR}`,
              color: "white",
            }}
            onClick={(e) => {
              e.preventDefault();
              handleOnPageChange(i);
            }}
          >
            {i}
          </Label>
        </GridColumn>
      );

      labels.push(label);
    }

    return labels;
  };

  const generatePost = () => {
    let topBoundary = currentPage + NUM_NUMBERS;

    if (topBoundary > totalNumberOfPages) {
      topBoundary = totalNumberOfPages;
    }

    const labels = [];

    for (let i = currentPage + 1; i <= topBoundary; i++) {
      const label = (
        <GridColumn textAlign="center" key={i}>
          <Label
            size="large"
            circular
            key={i}
            style={{
              cursor: "pointer",
              backgroundColor: `${COLORS.CONTRAST_COLOR}`,
              color: "white",
            }}
            onClick={(e) => {
              e.preventDefault();
              handleOnPageChange(i);
            }}
          >
            {i}
          </Label>
        </GridColumn>
      );

      labels.push(label);
    }

    return labels;
  };

  return (
    <Grid textAlign="center" style={{ gap: `${width > 1000 ? 0 : "10%"}` }}>
      <Grid.Column
        style={{ textAlign: "right" }}
        onClick={(e) => {
          e.preventDefault();
          handleOnPageChange(1);
        }}
      >
        <i
          className={`big angle double left icon`}
          style={{
            cursor: `${isPreviousPageAvailable ? "pointer" : ""}`,
            color: `${
              !isPreviousPageAvailable
                ? COLORS.DISABLED_GREY
                : COLORS.CONTRAST_COLOR
            }`,
          }}
        />
      </Grid.Column>
      <Grid.Column
        style={{ textAlign: "right" }}
        onClick={(e) => {
          e.preventDefault();
          handleOnPageChange(currentPage - 1);
        }}
      >
        <i
          className={`big angle left icon`}
          style={{
            cursor: `${isPreviousPageAvailable ? "pointer" : ""}`,
            color: `${
              !isPreviousPageAvailable
                ? COLORS.DISABLED_GREY
                : COLORS.CONTRAST_COLOR
            }`,
          }}
        />
      </Grid.Column>
      {width > 1000 && generatePre()}
      <Grid.Column>
        <div
          style={{
            fontWeight: "650",
            fontSize: "1.7em",
            textAlign: "center",
            color: `${COLORS.DEFAULT_BLUE}`,
          }}
        >
          {currentPage}/{totalNumberOfPages}
        </div>
      </Grid.Column>
      {width > 1284 && generatePost()}
      <Grid.Column
        onClick={(e) => {
          e.preventDefault();
          isNextPageAvailable && handleOnPageChange(currentPage + 1);
        }}
      >
        <i
          className={`big angle right icon`}
          style={{
            cursor: `${isNextPageAvailable ? "pointer" : ""}`,
            color: `${
              !isNextPageAvailable
                ? COLORS.DISABLED_GREY
                : COLORS.CONTRAST_COLOR
            }`,
          }}
        />
      </Grid.Column>
      <Grid.Column
        onClick={(e) => {
          e.preventDefault();
          isNextPageAvailable && handleOnPageChange(totalNumberOfPages);
        }}
      >
        <i
          className={`big angle double right icon`}
          style={{
            cursor: `${isNextPageAvailable ? "pointer" : ""}`,
            color: `${
              !isNextPageAvailable
                ? COLORS.DISABLED_GREY
                : COLORS.CONTRAST_COLOR
            }`,
          }}
        />
      </Grid.Column>
    </Grid>
  );
};

export default Pag;
