export const TEXTS = {
  SEEN: "Pokud naši aplikaci používáte častěji, můžete si výzvy, které jste již viděli, označit jako zpracované.",
  NEWS: "Jedná se o nově identifikované dotační výzvy a také změny u dříve přidaných výzev. V pravém horním rohu můžete vidět kolik změn ve výzvě od posledního stažení proběhlo.",
  HEADER:
    "Aplikace pro automatické stahování dotačních výzev. V pravidelných intervalech strojově analyzujeme stránky ministerstev a poskytovatelů dotací. Přinášíme nejenom identifikaci nových výzev, ale i informace o změnách a nových dokumentech, které ve výzvách přibyly.",
  FOOTER: (
    <div>
      Provozovatelem služby je společnost EU LEGAL ADVISORY, s.r.o. Dodavatelem
      technického řešení pro stahování dat a strojové identifikace podstatných
      informací je společnost CCF RESEARCH, a.s. ve spolupráci s Mendelovou
      univerzitou v Brně.
    </div>
  ),
};
