import React from "react";
import { useSelector } from "react-redux";
import { Card, Grid } from "semantic-ui-react";
import GrantCard from "../cardGrant/GrantCard";
import TopFilter from "./components/TopFilter";
import NoGrants from "./components/NoGrants";

const renderGrants = (grants) => {
  return grants.map((grant) => {
    return <GrantCard key={grant.grants_id} grant={grant} />;
  });
};

const CardsGrants = () => {
  const grants = useSelector((state) => Object.values(state.grants));
  if (grants === {}) {
    return null;
  }

  const noGrants = grants.length < 1;
  const containsUnwanted = grants.filter(
    (grant) => grant.changes !== undefined
  ).length;

  if (noGrants || containsUnwanted) {
    return (
      <div>
        <Grid stackable style={{ marginBottom: "20px" }}>
          <div className="ui column mobile hidden">
            <TopFilter text="Řadit podle" red="sortBy" val="by" disabled />
          </div>
        </Grid>
        <Card.Group itemsPerRow={3} stackable centered>
          <NoGrants />
        </Card.Group>
      </div>
    );
  }

  return (
    <>
      <Grid stackable style={{ marginBottom: "20px" }}>
        <div className="ui column mobile hidden">
          <TopFilter text="Řadit podle" red="sortBy" val="by" />
        </div>
      </Grid>
      <Card.Group itemsPerRow={3} stackable>
        {renderGrants(grants)}
      </Card.Group>
    </>
  );
};

export default CardsGrants;
