import { Grid, Message } from "semantic-ui-react";

const ErrorMessage = ({ message, onDismiss }) => (
  <Message negative onDismiss={onDismiss}>
    <Message.Header id="fonts">Pri přihlašovaní nastal problem</Message.Header>
    <p>{message}</p>
  </Message>
);

export const LoginErrors = ({ errors, clearErrors }) => {
  if (!errors) {
    return null;
  }

  console.log("errors", errors);
  return (
    errors.message && (
      <Grid.Row>
        <ErrorMessage message={errors.message} onDismiss={clearErrors} />
      </Grid.Row>
    )
  );
};
