import React, { useEffect } from "react";
import { Icon, Header, Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { editIsDoneOne, fetchGrant } from "../../actions";
import GrantDetail from "./components/GrantBasicInfo";
import GrantExtended from "./components/GrantExtended";
import { handleSimpleChanges } from "../../common";
import Loading from "../homePage/components/Loading";
import { LoggedComponent } from "../homePage/components/LoggedComponent";
import { useUser } from "../../helper/hooks/useUser";
import { useLogout } from "../../helper/hooks/useLogout";
import { COLORS } from "../../common/colors";
import { Layout } from "../Layout";
import { isBrowser } from "react-device-detect";

const GrantPage = (props) => {
  const { userToken } = useUser();
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const grant = useSelector((state) => state.grants[props.match.params.id]);
  const isLoading = useSelector((state) => state.common.isLoading);

  useEffect(() => {
    if (!grant?.data.supported_activities) {
      dispatch(fetchGrant(props.match.params.id));
    }
  });

  if (!grant?.data.supported_activities) {
    return null;
  }

  const { data, changes } = grant;
  const isDone = data.is_done ? "zpracováno" : "nezpracováno";

  const handleIconClick = async () => {
    dispatch(editIsDoneOne(data.is_done, data.grants_id, userToken, logout));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Layout>
      <Grid
        stackable
        flexDirection="column"
        marginLeft="100px"
        style={isBrowser ? { paddingLeft: "5em", paddingTop: "1em" } : {}}
      >
        <Header as="h1" style={{ color: COLORS.DEFAULT_BLUE }} id="fonts">
          {handleSimpleChanges(changes.headline, data.headline)}
        </Header>
        <LoggedComponent>
          <Header as="h3" style={{ marginTop: "-0.14285714em" }}>
            <Icon
              style={{
                cursor: "pointer",
                color: `${data.is_done ? COLORS.CONTRAST_COLOR : "gray"}`,
              }}
              circular
              name={`${data.is_done ? "check" : "check"}`}
              onClick={handleIconClick}
            />
            Momentálně {isDone}
          </Header>
        </LoggedComponent>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "25px",
          }}
        >
          <GrantDetail grant={data} changes={changes} />
          <GrantExtended grant={data} changes={changes} />
        </div>
      </Grid>
    </Layout>
  );
};

export default GrantPage;
