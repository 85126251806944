import React from "react";
import { isMobile } from "react-device-detect";
import { Header, List } from "semantic-ui-react";
import {
  handleArrayOperationsLinks,
  handleArrayOperationsNormal,
  handleSimpleChanges,
} from "../../../common";
import { COLORS } from "../../../common/colors";

const singleDetail = (headline, data, change) => {
  if (!data) {
    return null;
  }

  return (
    <div style={{ marginBottom: "2em" }}>
      <h2
        style={{
          lineHeight: "0.5em",
          marginRight: "1em",
          color: COLORS.DEFAULT_BLUE,
        }}
      >
        {headline}:
      </h2>
      {handleSimpleChanges(change, data)}
    </div>
  );
};

const listDetail = (
  headline,
  data,
  modifiedDocs,
  deletedDocs,
  key = undefined
) => {
  if (!data || data.length === 0) {
    return null;
  }

  data = [
    ...new Set(Object.values(data).concat(deletedDocs).concat(modifiedDocs)),
  ];

  return (
    <div
      style={{
        paddingLeft: "0em",
        width: `${isMobile ? "100%" : "50%"}`,
        paddingRight: "20px",
      }}
    >
      <div className="" style={{ marginBottom: "2em" }}>
        <h2 style={{ lineHeight: "0.5em", color: COLORS.DEFAULT_BLUE }}>
          {headline}
        </h2>
        <div className="ui bulleted list" style={{ marginLeft: "3em" }}>
          {data.map((row) => {
            return handleArrayOperationsNormal(
              row,
              modifiedDocs,
              deletedDocs,
              key
            );
          })}
        </div>
      </div>
    </div>
  );
};

const listUrls = (
  headline,
  data,
  modifiedDocs,
  deletedDocs,
  key = undefined
) => {
  if (!data || data.length === 0) {
    return null;
  }

  data = Object.values(data);
  return (
    <div
      style={{
        paddingLeft: "0em",
        width: `${isMobile ? "100%" : "50%"}`,
        paddingRight: "20px",
      }}
    >
      <div style={{ marginBottom: "2em" }}>
        <Header
          as="h2"
          style={{ lineHeight: "0.5em", color: COLORS.DEFAULT_BLUE }}
        >
          {headline}
        </Header>
        <List bulleted style={{ marginLeft: "3em" }}>
          {data.map((row, index) => (
            <div className="item" key={index + row.name}>
              {handleArrayOperationsLinks(row, modifiedDocs, deletedDocs)}
            </div>
          ))}
        </List>
      </div>
    </div>
  );
};

const GrantExtended = ({ grant, changes }) => {
  return (
    <div
      style={{
        paddingLeft: "0em",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {singleDetail("Popis", grant.description, changes.description)}
      {singleDetail("Program", grant.program.program)}
      <div
        style={{
          paddingLeft: "0em",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {listDetail(
          "Omezení",
          grant.restriction,
          changes.restriction_modified,
          changes.restriction_deleted
        )}
        {listDetail(
          "Podporované aktivity",
          grant.supported_activities,
          changes.supported_activities_modified,
          changes.supported_activities_deleted
        )}
        {listDetail(
          "Mají nárok",
          grant.receivers,
          changes.receivers_modified,
          changes.receivers_deleted,
          "name"
        )}
        {listDetail(
          "Forma podpory",
          grant.support_form,
          changes.support_form_modified,
          changes.support_form_deleted
        )}
        {listUrls(
          "Dostupné dokumenty",
          grant.documents,
          changes.documents_modified,
          changes.documents_deleted
        )}
      </div>
    </div>
  );
};

export default GrantExtended;
