import React from "react";
import { Button, Header, Modal } from "semantic-ui-react";

function GrantModal({ isOpen, setModelOpen }) {
  return (
    <Modal onClose={() => setModelOpen(false)} open={isOpen}>
      <Modal.Header id="fonts">Nejste přihlášen</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header id="fonts">Víte, že nevyužíváte všechny funkce?</Header>
          <p>
            V současné chvíli využíváte bezplatný přístup k aplikaci Dotační
            Manager, kde jsou zobrazeny strojově analyzované dotační výzvy. V
            případě, že si předplatíte plnou verzi aplikace, získáte přístup ke
            všem dotačním výzvám, tedy i těm, které přidáváme ručně. Budete také
            moci označovat již zpracované dotační výzvy, což ocení především
            profesionální dotační agentury.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setModelOpen(false)}>
          Ne, děkuji
        </Button>
        <Button
          content="Ukaž mi více"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            window.open("https://www.dotacni.info/prehled-dotaci", "_blank");
            setModelOpen(false);
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

export default GrantModal;
