import appBaseUrl from "../apis/app";
import {
  FETCH_GRANT,
  FETCH_GRANTS,
  EDIT_PAGE,
  FETCH_FILTERS,
  ERROR_MSG,
} from "./types";
import _ from "lodash";

const convertHow = (how) => {
  return how === 1 ? "asc" : how === 2 ? "desc" : "";
};

const convertWasSeen = (wasSeen) => {
  return wasSeen ? "isDone" : "isNotDone";
};

const convertHasNews = (hasNews) => {
  return hasNews ? "hasNews" : "hasNotNews";
};

const convertBy = (by) => {
  if (by === 1) {
    return "end";
  }

  if (by === 2) {
    return "start";
  }

  if (by === 3) {
    return "create";
  }

  return "";
};

const convertStatus = (status) => {
  if (status === 1) {
    return "opened";
  }

  if (status === 2) {
    return "closed";
  }

  if (status === 3) {
    return "prepared";
  }

  return "";
};

export const fetchGrants = ({
  currentPage: page,
  search,
  domain,
  program,
  category,
  how,
  by,
  status,
  wasSeen,
  hasNews,
  userToken,
  logout,
}) => {
  return async (dispatch) => {
    dispatch(editPage({ isLoading: true }));

    const headersToken = {
      Authorization: `Bearer ${userToken}`,
    };

    const headersNoToken = {};

    const headers = userToken ? headersToken : headersNoToken;
    const url = `/grants?limit=9&page=${page}${
      search !== "" ? `&search=${search}` : ""
    }${domain !== 0 ? `&domain_id=${domain}` : ""}${
      program !== 0 ? `&program_id=${program}` : ""
    }${category !== 0 ? `&category_id=${category}` : ""}${
      how !== 0 ? `&sort_how=${convertHow(how)}` : ""
    }${by !== 0 ? `&sort_by=${convertBy(by)}` : ""}${
      wasSeen !== 0 && userToken ? `&done=${convertWasSeen(wasSeen)}` : ""
    }${
      hasNews !== 0 && hasNews !== false
        ? `&news=${convertHasNews(hasNews)}`
        : ""
    }${status !== 0 ? `&status=${convertStatus(status)}` : ""}`;

    // // category_id je separatne pretoze sa na BE s nim pracuje v specialnej tabulke
    // // a nechce sa mi riesit upravovanie Pydantic modelu..
    // const filterObject = {
    //   filters: {
    //     page,
    //     search,
    //     domain_id: domain || undefined,
    //     program_id: program || undefined,
    //     sort_how: convertHow(how) || undefined,
    //     sort_by: convertBy(by) || undefined,
    //     done: wasSeen !== 0 && userToken ? convertWasSeen(wasSeen) : undefined,
    //     news:
    //       hasNews !== 0 && hasNews !== false
    //         ? convertHasNews(hasNews)
    //         : undefined,
    //     status: convertStatus(status) || undefined,
    //   },
    //   category_id: category || undefined,
    // };
    // await appBaseUrl.post(
    //   "/grants/",
    //   {
    //     ...filterObject,
    //   },
    //   {
    //     headers,
    //   }
    // );
    await appBaseUrl
      .get(url, {
        headers,
      })
      .then((data) => {
        dispatch({
          type: ERROR_MSG,
          payload: undefined,
        });
        dispatch(editPage({ totalCount: data.data.total_count }));
        dispatch({
          type: FETCH_GRANTS,
          payload: data,
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          dispatch({
            type: ERROR_MSG,
            payload:
              "Bohužel se nám nepodařilo najít žádné dotace. O chybě víme a brzy ji opravíme.",
          });
        }
      })
      .finally(() => {
        dispatch(editPage({ isLoading: false }));
      });
  };
};

export const fetchGrant = (id) => {
  return async (dispatch) => {
    dispatch(editPage({ isLoading: true }));
    const data = await appBaseUrl.get(`/grants/${id}`).finally(() => {
      dispatch(editPage({ isLoading: false }));
    });
    dispatch({
      type: FETCH_GRANT,
      payload: data,
    });
  };
};

export const handleWasSeen = async (wasSeen, id, token) => {
  if (!wasSeen) {
    return await appBaseUrl.post(
      `/grant_user`,
      { grants_id: id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  return await appBaseUrl.delete(`/grant_user`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: { grants_id: id },
  });
};

export const editIsDoneOne = (wasSeen, id, token, logoutCallback) => {
  return async (dispatch) => {
    await handleWasSeen(wasSeen, id, token)
      .then(() => {
        setTimeout(() => {
          dispatch(fetchGrant(id));
        }, 500);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logoutCallback();
        } else {
          dispatch({
            type: ERROR_MSG,
            payload:
              "Bohužel se nám nepodařilo správne načítat dotaci. O chybě víme a brzy ji opravíme.",
          });
        }
      });
  };
};

export const editIsDone = (
  id,
  search,
  currentPage,
  domain,
  program,
  category,
  how,
  by,
  status,
  wasSeen,
  hasNews,
  token,
  logoutCallback
) => {
  return async (dispatch) => {
    await handleWasSeen(wasSeen, id, token)
      .then(() => {
        setTimeout(() => {
          dispatch(editPage({ currentPage: 1 }));
          dispatch(
            fetchGrants(
              search,
              currentPage,
              domain,
              program,
              category,
              how,
              by,
              status,
              wasSeen,
              hasNews,
              token
            )
          );
        }, 500);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logoutCallback();
        } else {
          dispatch({
            type: ERROR_MSG,
            payload:
              "Bohužel se nám nepodařilo správne načítat dotaci. O chybě víme a brzy ji opravíme.",
          });
        }
      });
  };
};

export const fetchFilters = () => {
  return async (dispatch) => {
    const domains = await appBaseUrl.get("/domains");
    const programs = await appBaseUrl.get("/programs");
    const categories = await appBaseUrl.get("/categories");
    let data = { ...domains.data, ...programs.data, ...categories.data };

    const ids = ["domains_id", "programs_id", "categories_id"];
    Object.values(data).forEach((d, index) => {
      const id = ids[index];
      dispatch({
        type: FETCH_FILTERS,
        payload: { [id]: _.mapKeys(d, id) },
      });
    });
  };
};

export const editPage = (data) => {
  return {
    type: EDIT_PAGE,
    payload: data,
  };
};

export const login = ({ username, password }, setError, successfullyLogged) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("grant_type", "password");

    dispatch(editPage({ isLoading: true }));
    await appBaseUrl
      .post(`/token`, formData)
      .then(({ data }) => {
        const { access_token, token_type } = data;
        localStorage.setItem("token", access_token);
        localStorage.setItem("token_type", token_type);
        successfullyLogged();
      })
      .catch((err) => {
        setError({
          message: err.response.data.detail,
          userName: username,
          password: password,
        });
      })
      .finally(() => {
        dispatch(editPage({ isLoading: false }));
      });
  };
};
