import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { isMobile } from "react-device-detect";
import {
  Grid,
  Icon,
  Popup,
  Header,
  Transition,
  Label,
  Card,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { editIsDone } from "../../../../actions";
import { useFilter } from "../../../../common";
import "./grantCard.css";
import { LoggedComponent } from "../LoggedComponent";
import { useUser } from "../../../../helper/hooks/useUser";
import { useLogout } from "../../../../helper/hooks/useLogout";
import { COLORS } from "../../../../common/colors";

const wasNotSeen = (number) => {
  if (Number(number) === 0) {
    return `Nová dotace`;
  }

  return `Počet změn: ${number}`;
};

const generateCornerContent = (number) => {
  if (Number(number) === 0) {
    return <Icon name="bell" />;
  }

  return number;
};
const linkToGrant = "K originální dotaci";
const isNotDone = "Momentálně nezpracováno. Označit ako zpracováno";
const isDone = "Zpracováno. Označit ako nezpracováno";
const maxDescriptionLenghtInCharacters = 400;

const GrantCard = ({ grant }) => {
  const wasSeenGrants = useSelector((state) => state.common.wasSeen);
  const [isVisible, setIsVisible] = useState(true);
  const { userToken } = useUser();
  const { logout } = useLogout();

  const {
    currentPage,
    search,
    domain,
    program,
    category,
    how,
    by,
    status,
    wasSeen,
    hasNews,
  } = useFilter();

  const renderStatus = () => {
    const today = new Date();

    if (new Date(grant.deadline_date) < today) {
      return (
        <Label id="status_label" color="red">
          Uzavřená
        </Label>
      );
    }

    if (new Date(grant.start_date) > today) {
      return (
        <Label id="status_label" color="yellow">
          Připravovaná
        </Label>
      );
    }

    return (
      <Label id="status_label" color="olive">
        Otevřená
      </Label>
    );
  };

  const desc = React.useMemo(() => {
    const { description } = grant;

    if(!description || description.length <= maxDescriptionLenghtInCharacters){
      return description
    }

    return description.slice(0, maxDescriptionLenghtInCharacters).concat("...")
  }, [grant])

  const dispatch = useDispatch();
  return (
    <Transition visible={isVisible} animation="scale" duration={350}>
      <Card
        fluid
        color={hasNews && "blue"}
        style={isMobile ? {} : { marginLeft: "0" }}
      >
        {(grant.num_changes > 0 || grant.is_new) && (
          <Popup
            content={wasNotSeen(grant.num_changes)}
            trigger={
              <div
                className='ui right corner label'
                style={{ borderColor: `${COLORS.CONTRAST_COLOR}` }}
              >
                <div
                  style={{
                    marginTop: "13%",
                    marginLeft: "20%",
                    color: "white",
                  }}
                >
                  {generateCornerContent(grant.num_changes)}
                </div>
              </div>
            }
          />
        )}
        <Card.Content>
          <Header as="h3" id="fonts">
            <Link
              to={`grant/${grant.grants_id}`}
              style={{
                textDecoration: "none",
                marginRight: "1em",
                marginBottom: "0.1em",
              }}
              key={grant.grants_id}
            >
              {grant.headline}
            </Link>
          </Header>

          <div style={{ paddingBottom: "4em" }}>
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>
                  {renderStatus()}
                  {format(new Date(grant.start_date), "dd.MM.yyyy")} -{" "}
                  {format(new Date(grant.deadline_date), "dd.MM.yyyy")}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row id="description_row">
                <p>{desc}</p>
              </Grid.Row>
            </Grid>
          </div>
        </Card.Content>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            paddingLeft: "1em",
            paddingBottom: "0.5em",
            paddingRight: "1em",
            paddingTop: "0.3em",
            width: "100%",
            borderTop: "1.5px solid rgba(34,36,38,.1)",
            color: "rgba(0,0,0,.4)",
          }}
        >
          {format(new Date(grant.retrieved_at), "dd.MM.yyyy")}
          <LoggedComponent>
            <Popup
              content={`${wasSeenGrants ? isDone : isNotDone}`}
              trigger={
                <Icon
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                  circular
                  color={`${wasSeenGrants ? `${COLORS.DEFAULT_BLUE}` : "grey"}`}
                  name={`${wasSeenGrants ? "check" : "check"}`}
                  onClick={async () => {
                    setIsVisible(false);
                    dispatch(
                      editIsDone(
                        grant.grants_id,
                        search,
                        currentPage,
                        domain,
                        program,
                        category,
                        how,
                        by,
                        status,
                        wasSeen,
                        hasNews,
                        userToken,
                        logout
                      )
                    );
                  }}
                />
              }
            />
          </LoggedComponent>
          <span className="right floated">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={grant.url}
              style={{ color: "rgba(0,0,0,.4)" }}
            >
              {grant.domain.domain}
            </a>
            <a target="_blank" rel="noopener noreferrer" href={grant.url}>
              <Popup
                content={linkToGrant}
                trigger={
                  <Icon
                    style={{
                      marginLeft: "10px",
                      color: `${COLORS.DEFAULT_BLUE}`,
                    }}
                    link
                    circular
                    name="search"
                  />
                }
              />
            </a>
          </span>
        </div>
      </Card>
    </Transition>
  );
};

export default GrantCard;
