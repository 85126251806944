import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editPage } from "../../../actions";
import { Icon } from "semantic-ui-react";
import { COLORS } from "../../../common/colors";

const SearchByName = ({ isMobile }) => {
  const dispatch = useDispatch();
  const typedSearch = useSelector((state) => state.common.search);
  const [search, setSearch] = useState(typedSearch);

  const removeSearch = () => {
    dispatch(editPage({ currentPage: 1 }));
    dispatch(editPage({ search: "" }));
    setSearch("");
  };
  const triggerSearch = () => {
    dispatch(editPage({ currentPage: 1 }));
    dispatch(editPage({ search: search }));
    setSearch("");
  };

  const renderIcon = (isEmpty) => {
    if (isEmpty) {
      return (
        <Icon
          className="remove-search"
          style={isMobile ? { fontSize: "0.85714286em" } : {}}
          link
          color="black"
          name="search"
          onClick={triggerSearch}
        />
      );
    }

    return (
      <Icon
        className="remove-search"
        style={isMobile ? { fontSize: "0.85714286em" } : {}}
        link
        color="black"
        name="close"
        onClick={removeSearch}
      />
    );
  };

  return (
    <div
      className="ui fluid icon input"
      style={isMobile ? { minWidth: "14em" } : {}}
    >
      <input
        type="input"
        placeholder="Název dotace"
        value={search}
        name="nazev_dotace"
        style={{
          border: `1px solid ${
            search === "" ? "rgba(34,36,38,.15)" : `${COLORS.DEFAULT_BLUE}`
          }`,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            dispatch(editPage({ search: search }));
            dispatch(editPage({ currentPage: 1 }));
          }
        }}
        onChange={(e) => setSearch(e.target.value)}
      />
      {renderIcon(typedSearch === "")}
    </div>
  );
};

export default SearchByName;
